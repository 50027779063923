import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import 'tailwindcss/tailwind.css';
import './fonts/Nimbus-Sans-Black-Extended.ttf';
import './index.scss';

import Home from './Home';
import Form from './Form';
import FormSuccess from './FormSuccess';
import FourOhFour from './FourOhFour';
import ContestRules from './ContestRules';

ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route path="/submit">
                <Form/>
            </Route>
            <Route path="/success">
                <FormSuccess/>
            </Route>
            <Route path="/rules">
                <ContestRules/>
            </Route>
            <Route path="*">
                <FourOhFour/>
            </Route>
        </Switch>
    </Router>,
    document.getElementById('root')
);
