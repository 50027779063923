import {useLocation} from "react-router-dom";

function FourOhFour() {
    let location = useLocation();
    return (
        <div>
            <h1>
                404 Not Found
            </h1>
            <p>
                The path <code>{location.pathname}</code> was not found on this server.
            </p>
        </div>
    );
}

export default FourOhFour;
