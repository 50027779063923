import fanupLogo from "./images/fanup-logo.svg";
import exploreEdmontonLogo from "./images/explore-edmonton-logo.svg";
import socialInstagram from "./images/social-instagram.svg";
import socialLinkedIn from "./images/social-linkedin.svg";
import socialTwitter from "./images/social-twitter.svg";
import React from "react";
import step1Image from "./images/step-1-ready-your-cheer-face.jpg";
import step2Image from "./images/step-2-take-a-photo.jpg";
import step3Image from "./images/step-3-submit-your-photo.jpg";
import manCheerFaceImage from "./images/man-cheer-face.jpg";

function FormHeader() {
    return <div>

        <SiteHeader/>

        {/*Heading*/}
        <div className="relative container mx-auto flex flex-col text-center items-center w-8/12">
            <h1 className="w-full
                    text-5xl
                    lg:text-9xl" style={{
                lineHeight: '0.85'
            }}>
                Show<br/>
                us<br/>
                <img src={manCheerFaceImage} alt="Excited man showing his cheer face" className="inline-block w-full
                    -mt-1
                    lg:-mt-5"
                     style={{width: '486px'}}/><br/>
                your cheer face
            </h1>
        </div>
    </div>;
}

function FormFooter() {
    return <div>
        {/*Soccer Ball*/}
        <div className="bg-right-top left-0 -mt-40
            soccer-ball soccer-ball-w-180
            lg:soccer-ball lg:soccer-ball-w-360
        "></div>

        {/*How to Enter / Submit*/}
        <div className="container mx-auto flex flex-col justify-center w-1/2 mt-16">
            <h2 className="text-center
                    text-2xl
                    lg:text-6xl
                 ">
                How to Enter
            </h2>
        </div>

        {/*Step 1 - 3*/}
        <HowToEnterSteps/>
        <div className="pt-20"/>

        <SiteFooter/>
    </div>;
}

function SiteHeader() {
    return <div>
        <div className="bg-left-top soccer-ball lg:soccer-ball -inset-12"></div>
        <div className="py-4 lg:py-10">
            <a href="/">
                <img className="mx-auto w-20 lg:w-60" src={fanupLogo} alt="FanUp 2026 World Cup Bid Edmonton Logo"/>
            </a>
        </div>
    </div>
}

function SiteFooterText() {
    return <p className="mt-4">
        &copy; Explore Edmonton
        |
        <a href="https://exploreedmonton.com/privacy-policy" target="_blank" rel="noreferrer"
           className="pl-2 no-underline">Privacy Policy</a>
    </p>;
}

function SiteFooter() {
    return <div className="grid bg-white
    grid-cols-1 py-10 px-16
    lg:grid-cols-2 lg:py-20 lg:px-32
    ">
        <div className="text-center lg:text-left">
            <img className="mx-auto lg:mx-0" src={exploreEdmontonLogo} alt="Explore Edmonton Logo" style={{
                width: '136px'
            }}/>
            <div className="hidden lg:block">
                <SiteFooterText/>
            </div>
        </div>
        <div className="text-center
        mt-6
        lg:text-right lg:mt-8">
            <a href="https://www.linkedin.com/company/explore-edmonton-corporation" target="_blank" rel="noreferrer">
                <img src={socialLinkedIn} alt="LinkedIn Logo" style={{width: '30px'}} className="inline mr-4"/>
            </a>

            <a href="https://www.instagram.com/exploreedmonton/" target="_blank" rel="noreferrer">
                <img src={socialInstagram} alt="Instagram Logo" style={{width: '30px'}} className="inline mr-4"/>
            </a>

            <a href="https://twitter.com/ExploreEdmonton" target="_blank" rel="noreferrer">
                <img src={socialTwitter} alt="Twitter Logo" style={{width: '30px'}} className="inline mr-4"/>
            </a>
        </div>

        <div className="text-center
        mt-2
        lg:hidden
        text-xs">
            <SiteFooterText/>
        </div>
    </div>
}

function HowToEnterSteps() {
    return <div className="w-8/12 mx-auto mt-10">
        <div className="grid
        grid-cols-1 gap-5
        lg:grid-cols-3 lg:gap-14
        ">
            <div>
                <strong className="uppercase block
                text-lg mb-2
                lg:text-2xl lg:tracking-tight lg:mb-4
                ">
                    <div className="lg:text-3xl">
                        Step 1:
                    </div>
                    Ready your Cheer Face
                </strong>

                <img src={step1Image} alt="Woman excitedly showing her cheer face"/>

                <p className="leading-relaxed text-xs mt-4 lg:text-lg">
                    We want to see it. We want the world to see it. Ecstatic, heartbreaking, or anywhere in-between, get
                    your Cheer Face on.
                </p>
            </div>
            <div>
                <strong className="uppercase block
                text-lg mb-2
                lg:text-2xl lg:tracking-tight lg:mb-4
                ">
                    <div className="lg:text-3xl">
                        Step 2:
                    </div>
                    Take a Photo
                </strong>

                <img src={step2Image} alt="Man and woman taking a selfie of their cheer face" className="xl:mt-12 image-how-to-enter-step-2"/>

                <p className="leading-relaxed text-xs mt-4 lg:text-lg">
                    Take a selfie or get someone to take it for you. Shoot from shoulders up. Just make sure your Cheer
                    Face is clear and in frame.
                </p>
            </div>
            <div>
                <strong className="uppercase block
                text-lg mb-2
                lg:text-2xl lg:tracking-tight lg:mb-4
                ">
                    <div className="lg:text-3xl">
                        Step 3:
                    </div>
                    Submit your Photo
                </strong>

                {/* eslint-disable-next-line */}
                <img src={step3Image} alt="Person using mobile phone to upload their photo" className="xl:mt-4 2xl:mt-12"/>

                <p className="leading-relaxed text-xs mt-4 lg:text-lg">
                    Hit the submit button above and upload your photo to share your Cheer Face (your enthusiasm
                    for the FIFA World Cup 2026™ in YEG) and for your chance to win one of three fantastic prizes.
                </p>
            </div>
        </div>
    </div>;
}

export {SiteHeader, SiteFooter, FormFooter, FormHeader, HowToEnterSteps};
