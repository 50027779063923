import React from 'react';
import {FormFooter, FormHeader} from "./Templates";

import socialInstagram from "./images/social-instagram.svg";
import socialTwitter from "./images/social-twitter.svg";
import socialFacebook from "./images/social-facebook.svg";
import socialYoutube from "./images/social-youtube.svg";

function FormSuccess() {
    return <div>
        <FormHeader/>

        {/*You Cheer Faced*/}
        <div className="w-8/12 container mx-auto flex flex-col justify-center
        mt-12
        lg:mt-28">
            <div id="thank-you"/>
            <h2 className="text-center
            text-2xl
            lg:text-5xl
             ">
                Thank you for your entry!
            </h2>

            <p className="text-center font-bold
            text-sm mt-20
            lg:text-lg
            ">
                Submissions are usually approved within 1 business day.
            </p>

            <h2 className="text-center
            text-xl
            lg:text-4xl
            normal-case
            font-extended
            mt-20
             ">
                Follow Us On Social for Updates and a Chance to See Your Cheer Face Featured!
            </h2>

            <div className="mt-10 text-center">
                <a href="https://www.facebook.com/EdmontonTourism/" target="_blank" rel="noreferrer">
                    <img src={socialFacebook} alt="Facebook" className="w-20 inline-block mr-8"/>
                </a>

                <a href="https://www.instagram.com/exploreedmonton/" target="_blank" rel="noreferrer">
                    <img src={socialInstagram} alt="Instagram" className="w-20 inline-block mr-8"/>
                </a>

                <a href="https://twitter.com/ExploreEdmonton" target="_blank" rel="noreferrer">
                    <img src={socialTwitter} alt="Twitter" className="w-20 inline-block mr-8"/>
                </a>

                <a href="https://www.youtube.com/user/exploreedmonton" target="_blank" rel="noreferrer">
                    <img src={socialYoutube} alt="YouTube" className="w-20 inline-block mr-8"/>
                </a>
            </div>

            <p className="text-center font-bold
            text-sm mt-20
            lg:text-lg
            ">
                Share your cheer face photos using the hashtag #FanUp2026
            </p>

            <a href="/#gallery" className="btn-submit mt-8
                   btn-submit-sm
                   lg:btn-submit-lg
                   ">
                Enter Gallery
            </a>
        </div>

        <div className="mt-40"/>
        <FormFooter/>
    </div>
}

export default FormSuccess;
