import React, {useCallback, useState} from 'react';
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Formik, FormikErrors} from 'formik';
import {FormFooter, FormHeader} from "./Templates";
import './Form.scss';
import FormData from "form-data";

const axios = require('axios').default;

// Max filexize: 10MB.
const MAX_FILESIZE_MB = 10;
const MAX_FILESIZE_BYTES = Math.pow(2, 20) * MAX_FILESIZE_MB;

function AppForm() {
    const submitUrl = '/wp/wp-content/plugins/ddb-cheer-face/submit.php';
    const fileInput = React.createRef<HTMLInputElement>();
    let [token, setToken] = useState();
    const onVerifyCaptcha = useCallback((newToken) => {
        setToken(newToken);
    }, [setToken]);
    const emailAddress = React.useRef('');
    const emailValidateInterval = React.useRef<number | undefined | NodeJS.Timer>();
    let [isEmailValidated, setIsEmailValidated] = useState(false);
    let [emailError, setEmailError] = useState('');

    // Form values interface for the Formik form.
    interface FormValues {
        firstName: string,
        lastName: string,
        emailAddress: string,
        photo: string,
        consent: string,
        subscribe: string,
        token: string
    }

    return <div>
        <FormHeader/>
        <GoogleReCaptchaProvider reCaptchaKey="6LeOn7ccAAAAAMf57144Daz8qvVLcta_qPOcxaTt">

            {/*Soccer Ball*/}
            <div className="bg-left-top right-0
            soccer-ball soccer-ball-w-180
            lg:soccer-ball lg:soccer-ball-w-360"></div>

            <div className="w-8/12 container mx-auto flex flex-col justify-center
            mt-10
            lg:mt-20">
                <h2 className="text-center
                    text-2xl -mt-3
                    lg:text-6xl lg:-mt-7
                 ">
                    Enter for a chance to win!
                </h2>

                <p className="text-xs lg:text-base text-center">
                    Your Cheer Face is your entry. What's Cheer Face? It's the face you make in those match-defining
                    moments.
                </p>
            </div>

            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    photo: '',
                    consent: '',
                    subscribe: '',
                    token: '',
                }}
                validate={values => {
                    let errors: FormikErrors<FormValues> = {};

                    // Update the token value.
                    if (token !== undefined) {
                        values.token = token;
                    }

                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }

                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }

                    if (emailError.length > 0) {
                        errors.emailAddress = emailError;
                    }

                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)) {
                        errors.emailAddress = 'Invalid email address';
                    } else if (!isEmailValidated || values.emailAddress !== emailAddress.current) {
                        emailAddress.current = values.emailAddress;

                        // Validate the email address.
                        window.clearTimeout(emailValidateInterval.current as number);
                        emailValidateInterval.current = setTimeout(function () {
                            let formData = new FormData();
                            formData.append('token', values.token);
                            formData.append('emailAddress', values.emailAddress);
                            formData.append('validate', '1');
                            axios.post(submitUrl, formData)
                                .then(function (response: any) {
                                    if (response?.data?.result === 'duplicate') {
                                        setIsEmailValidated(false);
                                        setEmailError('Duplicate address found.');
                                    } else {
                                        setIsEmailValidated(true);
                                        setEmailError('');
                                    }
                                });
                        }, 1000);

                        return errors;
                    }

                    // Validate the file size.
                    if (!values.photo) {
                        errors.photo = 'Photo is required';
                    } else {
                        const files = fileInput?.current?.files;
                        if (typeof files != "undefined" && files != null) {
                            for (let i = 0; i < files.length; i++) {
                                if (files[i].size > MAX_FILESIZE_BYTES) {
                                    errors.photo = 'Photo must be smaller than ' + MAX_FILESIZE_MB + 'MB';
                                }
                            }
                        } else {
                            errors.photo = 'Photo is required';
                        }
                    }

                    if (!values.consent) {
                        errors.consent = 'Required';
                    }

                    return errors;
                }}
                onSubmit={(values, actions) => {
                    // Do things before submitting the form.
                    actions.setSubmitting(true);

                    // Submit the full form.
                    let formData = new FormData();
                    formData.append('firstName', values.firstName);
                    formData.append('lastName', values.lastName);
                    formData.append('emailAddress', values.emailAddress);

                    const files = fileInput?.current?.files;
                    if (typeof files != "undefined" && files != null) {
                        formData.append('photo', files[0], files[0].name);
                    }
                    formData.append('photo', values.photo);

                    formData.append('consent', values.consent);
                    formData.append('subscribe', values.subscribe);
                    formData.append('token', values.token);

                    axios.post(submitUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(function (result: any) {
                            // Tell the form it has been submitted.
                            actions.setSubmitting(false);
                            window.location.pathname = '/success';
                        })
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form className="relative container mx-auto flex flex-col items-center
                    pt-10 w-full
                    lg:pt-20 lg:w-7/12"
                          onSubmit={handleSubmit}>

                        {/*First Name*/}
                        <div
                            className="form-group mb-2 px-6 lg:mb-5 lg:px-6 ">
                            <input className="form-control w-full
                                   mb-2 border-b text-sm
                                   lg:mb-4 lg:border-b-2 lg:text-xl"
                                   id="firstName"
                                   name="firstName"
                                   type="text"
                                   placeholder="First Name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                            <div className="text-error">{errors.firstName}</div>
                            }
                        </div>

                        {/*Last Name*/}
                        <div
                            className="form-group mb-2 px-6 lg:mb-5 lg:px-6 ">
                            <input className="form-control w-full
                                   mb-2 border-b text-sm
                                   lg:mb-4 lg:border-b-2 lg:text-xl"
                                   name="lastName"
                                   id="lastName"
                                   type="text"
                                   placeholder="Last Name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                            <div className="text-error">{errors.lastName}</div>
                            }
                        </div>

                        {/*Email Address*/}
                        <div
                            className="form-group mb-2 px-6 lg:mb-5 lg:px-6 ">
                            <input className="form-control w-full
                                   mb-2 border-b text-sm
                                   lg:mb-4 lg:border-b-2 lg:text-xl"
                                   name="emailAddress"
                                   id="emailAddress"
                                   type="email"
                                   placeholder="Email Address"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.emailAddress}
                            />
                            {errors.emailAddress && touched.emailAddress &&
                            <div className="text-error">{errors.emailAddress}</div>
                            }
                        </div>

                        <div className="mx-6 grid grid-cols-1 lg:grid-cols-12">
                            {/*Photo*/}
                            <label className="col-span-2 text-base lg:text-xl" htmlFor="photo">
                                Upload Photo
                                <span className="text-red-500">*</span>
                            </label>

                            <div className="col-span-10">
                                <input
                                    className="mb-4 ml-4 lg:ml-0"
                                    type="file"
                                    name="photo"
                                    accept="image/jpg, image/jpeg"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.photo}
                                    ref={fileInput}
                                />

                                {errors.photo && touched.photo &&
                                <div className="text-error">{errors.photo}</div>
                                }
                            </div>

                            {/*Consent*/}
                            <label className="col-span-2 text-base pt-2 lg:pt-2 lg:text-xl" htmlFor="consent">
                                Consent
                            </label>

                            <div className="col-span-10">
                                <div className="pl-4 lg:pl-0 lg:pt-2">
                                    <input className="form-checkbox"
                                           type="checkbox"
                                           name="consent"
                                           id="consent"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.consent}
                                    />
                                    <label htmlFor="consent" className="text-sm pl-2 pb-2">
                                        I consent to my photo being used in promotions related to the Cheer Face
                                        Campaign and Explore Edmonton as per the <a href="/rules" target="_blank"
                                                                                    rel="noreferrer">
                                            full contest rules and guidelines.
                                        </a>
                                    </label>

                                    {errors.consent && touched.consent &&
                                    <div className="text-error">{errors.consent}</div>
                                    }

                                    <br/>
                                    {/*Subscribe*/}
                                    <input className=""
                                           type="checkbox"
                                           name="subscribe"
                                           id="subscribe"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.subscribe}
                                    />
                                    <label htmlFor="subscribe" className="text-sm">
                                        &nbsp;Please subscribe to future updates about the 2026 FIFA games.
                                    </label>

                                    {errors.subscribe && touched.subscribe &&
                                    <div className="text-error">{errors.subscribe}</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <GoogleReCaptcha onVerify={onVerifyCaptcha}/>
                        <input type="hidden"
                               name="token"
                               onChange={handleChange}
                               onBlur={handleBlur}
                               value={values.token}
                        />
                        {errors.token && touched.token &&
                        <div className="text-error">{errors.token}</div>
                        }

                        <button className={"btn-submit " +
                        "btn-submit-sm mt-4 " +
                        "lg:btn-submit-lg " +
                        ((isSubmitting && 'disabled:opacity-50') || '')}
                                type="submit" disabled={isSubmitting}>
                            {isSubmitting &&
                            <div
                                className="inline-block animate-spin rounded-full h-3 w-3 border-b-2 border-white mr-2"></div>}
                            Submit Now
                        </button>
                    </form>
                )}
            </Formik>
        </GoogleReCaptchaProvider>
        <FormFooter/>
    </div>
}

export default AppForm;
