import React, {useEffect, useState} from 'react';
import './Home.scss';
import Masonry from 'react-masonry-component';
import {HowToEnterSteps, SiteFooter, SiteHeader} from "./Templates";
import prizePack1Image from "./images/prize-pack-1.jpg";
import prizePack2Image from "./images/prize-pack-2.jpg";
import prizePack3Image from "./images/prize-pack-3.jpg";

const Item = function ({element}: any) {
    return (
        <div className="gallery-image w-1/2 lg:w-1/3 p-1">
            <img src={element.src} alt="FanUp 2026 World Cup Bid Edmonton Logo"/>
        </div>
    );
}

function Photos({photoPage, setShowLoadMore, showLoadMore}: any) {
    const [data, setData] = useState([]);
    const photosPerPage = 20;
    let photoIndex = 0;

    // Store numPhotos as a reference so we can use it in useEffect
    // without losing it's state.
    const numPhotos = React.useRef(0);

    // Shuffle an array.
    // Retrieved on 2021-10-30 from https://stackoverflow.com/a/3718452.
    const shuffle = (items: any) => {
        for (let i = 0; i < items.length - 1; i++) {
            let j = i + Math.floor(Math.random() * (items.length - i));
            let temp = items[j];
            items[j] = items[i];
            items[i] = temp;
        }
        return items;
    }

    // Load the data from the JSON file. Gets called once on initial page load.
    useEffect(() => {
        async function getData() {
            const response = await fetch("/wp/wp-content/uploads/photos.json");
            let data = await response.json();

            // Shuffle the order of the photos on each page load.
            data = shuffle(data);

            numPhotos.current = data.length;
            setData(data);
        }

        if (data.length === 0) {
            getData();
        }
    });

    if (data.length === 0) {
        return <div>Loading...</div>;
    }

    // Don't show a "ragged edge" of photos at the bottom.
    let onLayoutComplete = function (items: any) {
        const imageElements = Array.from(document.getElementsByClassName('gallery-image') as HTMLCollectionOf<HTMLElement>);
        const numDisplayedPhotos = imageElements.length;
        if (numDisplayedPhotos >= numPhotos.current) {
            if (showLoadMore === true) {
                setShowLoadMore(false);
            }
            return;
        }

        // Find the top of the lowest image.
        let maxTopY = 0;
        for (let i = 0; i < items.length; i++) {
            if (items[i].position.y > maxTopY) {
                maxTopY = items[i].position.y;
            }
        }

        // Find the bottom of the image that's closest to the top of the lowest image.
        let highestImageBottom = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < items.length; i++) {
            let imageBottom = items[i].position.y + items[i].size.height;
            if (imageBottom > maxTopY && imageBottom < highestImageBottom) {
                highestImageBottom = imageBottom;
            }
        }

        // Resize the photo container so the bottom is flat.
        setTimeout(function () {
            const elements = Array.from(document.getElementsByClassName('gallery') as HTMLCollectionOf<HTMLElement>);
            elements[0].style.height = (highestImageBottom - 5) + 'px';
        }, 250);
    }

    return <Masonry
        className={'gallery'}
        options={{
            transitionDuration: 0
        }}
        onLayoutComplete={onLayoutComplete}
    >
        {data.map((item: any) => {
            if (photoIndex > photosPerPage * photoPage) {
                return '';
            }
            photoIndex++;

            return <Item
                element={item}
                key={item.key}
            />;
        })}
    </Masonry>;
}

function Home() {
    const [photoPage, setPhotoPage] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(true);

    return <div>
        <SiteHeader/>

        {/*Heading*/}
        <div className="relative container mx-auto flex flex-col text-center items-center w-8/12">
            <h1 className="w-full
            text-5xl pb-11
            lg:text-9xl" style={{
                lineHeight: '0.85'
            }}>
                Cheer<br/>Face.
            </h1>
        </div>

        {/*Video*/}
        <div className="container mx-auto flex flex-col justify-center -mt-14 w-2/3 lg:-mt-20 lg:w-1/2 -z-1" style={{fontSize: '26px'}}>
            <iframe
                title="FanUp 2026 World Cup Bid Edmonton Video"
                className="w-full h-48 lg:h-96"
                src="https://player.vimeo.com/video/641263080?h=56747901b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                frameBorder="0"
                style={{
                    width: '100%',
                }}>
            </iframe>

            <h2 className="text-center
            text-2xl -mt-3
            lg:text-6xl lg:-mt-7
            z-10
             ">
                We know you<br/>have one.
            </h2>
        </div>

        {/*Soccer Ball*/}
        <div className="bg-left-top right-0 -mt-48
        soccer-ball soccer-ball-w-180
        lg:soccer-ball lg:soccer-ball-w-360
        "></div>

        {/*Copy*/}
        <div className="container mx-auto flex flex-col justify-center
        pt-6 w-2/3
        lg:pt-12 lg:w-1/2
        ">
            <p className="font-bold
            text-sm leading-5
            lg:text-base lg:leading-8 ">
                It's that face you make when you're caught up in the excitement – the euphoria – of being at a World Cup
                match. And in 2026 you could be in the stands at a World Cup match in your very own city. A
                once-in-a-generation event! Right here in Edmonton. <br/>
                <br/>
                With Canada, Mexico and the United States hosting the FIFA World Cup 2026™, Edmonton’s mission is to be
                named an Official Host City for the matches hosted throughout North America. Showing your Cheer Face is
                one of the ways you can show your support of Edmonton’s selection as an Official Host City and to win
                amazing prizes!
            </p>

            <p className="
            mt-6
            text-sm leading-5
            lg:text-base lg:leading-8 ">
                But first...<br/>
                <br/>
                We need to show the world the enthusiasm Edmonton is known for to secure some FIFA World Cup 2026™
                matches. This is where your Cheer Face comes in.
                That <em>first-kick-that-gets-the-game-underway</em> face.
                The <em>moment-you-realize-this-penalty-could-win-it-all</em> face. The <em>game-winning goal</em> face.
                Cheer Face.<br/>
                <br/>
                And when you share your Cheer Face you have a chance to win some awesome prizes.
            </p>
        </div>

        {/*Soccer Ball*/}
        <div className="bg-right-top -left-10
        soccer-ball soccer-ball-w-180 -mt-0
        lg:soccer-ball lg:soccer-ball-w-360 lg:-mt-24 "></div>

        {/*How to Enter / Submit*/}
        <div className="container mx-auto flex flex-col justify-center text-center
        w-9/12 mt-8
        lg:mt-20 lg:w-1/2
        ">
            <h2 className="text-center
            text-2xl -mt-3
            lg:text-6xl lg:-mt-7
             ">
                How to Enter
            </h2>
            <p className="text-xs mt-4
            lg:text-base
            ">
                Your Cheer Face is your entry. What's Cheer Face? It's the face you make in those match-defining
                moments.
            </p>

            <div className="py-5 block text-center">
                <a href="/submit"
                   className="btn-submit
                   btn-submit-sm
                   lg:btn-submit-lg
                   "
                >
                    Submit Now
                </a>

                <span className="block text-xs
                mt-3
                lg:mt-6 lg:text-sm
                ">
                    For more details, view the <a href="/rules" target="_blank" rel="noreferrer">full rules and guidelines.</a>
                </span>
            </div>
        </div>

        {/*Step 1 - 3*/}
        <HowToEnterSteps/>

        {/*Soccer Ball*/}
        <div className="bg-left-top right-0 mt-80
         soccer-ball soccer-ball-w-180
         lg:soccer-ball lg:soccer-ball-w-360"></div>

        {/*Enter to Win*/}
        <div className="w-8/12 container mx-auto flex flex-col justify-center mt-10 lg:mt-20">
            <h2 className="text-center
            text-2xl -mt-3
            lg:text-5xl lg:-mt-7
             ">
                Enter to win one of these incredible prizes
            </h2>
            <p className="text-center
            mt-6
            text-xs leading-5
            lg:text-base lg:leading-8 ">
                Tickets to Canada’s FIFA World Cup Qatar 2022&trade; Qualifiers at Commonwealth Stadium. Canada Soccer
                National Team jerseys and swag. Your Cheer Face is your entry! Each entry made before the first draw
                date is eligible for all three draws. The sooner you submit your entry, the more chances you have to
                win!<br/>
                <br/>
                Conditions apply, see <a href="/rules" target="_blank" rel="noreferrer">full contest rules and
                guidelines.</a>
            </p>

            {/*Prizes*/}
            <div className="grid
            grid-cols-1 gap-y-4 gap-x-12 mt-10
            lg:grid-cols-3 lg:gap-y-8 lg:mt-20
            ">
                {/*Prize 1*/}
                <div>
                    <img src={prizePack1Image} alt="Tajon Buchanan, Liam Millar, and Alphonso Davies celebrating a goal."/>
                </div>

                <div className="col-span-2">
                    <h3 className="uppercase font-sans text-black font-bold font-sans
                    text-lg
                    lg:text-3xl
                    ">
                        Prize 1: Canada v Costa Rica Prize Pack
                    </h3>

                    <strong className="font-condensed-black text-regular uppercase
                    block text-base mt-4
                    lg:hidden
                    ">
                        Draw Date: November 8, 2021
                    </strong>

                    <p className="
                        my-4
                        text-xs leading-5
                        lg:text-base lg:leading-8 ">
                        Show us your Cheer Face for your chance at winning two Category 1 tickets to the <strong>FIFA
                        World Cup Qatar 2022™ Qualifiers: Men’s National Team v Costa Rica</strong> on
                        November 12, 2021 at Commonwealth Stadium. While you’re at the game, you can sport your two new
                        Canada Soccer National Team jerseys and rest up with a one night stay in a Premium King Room at
                        the Matrix Hotel, complimentary self-parking, and complimentary breakfast for two at Loft
                        106. <strong>This prize pack is valued at $610.</strong>
                    </p>

                    <strong className="font-condensed-black text-regular uppercase
                    hidden
                    lg:block lg:text-2xl">
                        Draw Date: November 8, 2021
                    </strong>
                </div>

                {/*Prize 2*/}
                <div>
                    <img src={prizePack2Image} alt="Jonathan David goal celebration with Stephen Eustáquio"/>
                </div>
                <div className="col-span-2">
                    <h3 className="uppercase font-sans text-black font-bold
                    text-lg
                    lg:text-3xl
                    ">
                        Prize 2: Canada v Mexico Prize Pack
                    </h3>

                    <strong className="font-condensed-black text-regular uppercase
                    block text-base mt-4
                    lg:hidden
                    ">
                        Draw Date: November 15, 2021
                    </strong>

                    <p className="
                        my-4
                        text-xs leading-5
                        lg:text-base lg:leading-8 ">
                        Show us your Cheer Face for your chance to win two Category 1 tickets to the <strong>FIFA World
                        Cup Qatar 2022™ Qualifiers: Men’s National Team v Mexico</strong> on November 16, 2021 at
                        Commonwealth Stadium. While you’re at the game, you can sport your two new Canada Soccer
                        National Team jerseys and rest up with a one night stay in a Premium King Room at the Matrix
                        Hotel, complimentary self-parking, and complimentary breakfast for two at Loft 106. <strong>This
                        prize pack is valued at $610.</strong>
                    </p>

                    <strong className="font-condensed-black text-regular uppercase
                    hidden
                    lg:block lg:text-2xl">
                        Draw Date: November 15, 2021
                    </strong>
                </div>

                {/*Prize 3*/}
                <div>
                    <img src={prizePack3Image} alt="Alphonso Davies goal celebration with Jonathan David"/>
                </div>
                <div className="col-span-2">
                    <h3 className="uppercase font-sans text-black font-bold
                    text-lg
                    lg:text-3xl
                    ">
                        Prize 3: Ultimate Prize Pack
                    </h3>

                    <strong className="font-condensed-black text-regular uppercase
                    block text-base mt-4
                    lg:hidden
                    ">
                        Draw Date: November 22, 2021
                    </strong>

                    <p className="
                        my-4
                        text-xs leading-5
                        lg:text-base lg:leading-8 ">
                        Win a 1-year subscription to DAZN for your fix of over-the-top global sports video streaming,
                        two season tickets to FC Edmonton's 2022 season, and one Canada Soccer National Team jersey –
                        signed by the entire Men's National Team! <strong>This prize pack is valued at $900.</strong>
                    </p>

                    <strong className="font-condensed-black text-regular uppercase
                    hidden
                    lg:block lg:text-2xl">
                        Draw Date: November 22, 2021
                    </strong>
                </div>
            </div>
        </div>

        {/*Soccer Ball*/}
        <div className="bg-right-top left-0 mt-10
        soccer-ball soccer-ball-w-180
        lg:soccer-ball lg:soccer-ball-w-360"></div>

        {/*You Cheer Faced*/}
        <div id="gallery"/>
        <div className="w-8/12 container mx-auto flex flex-col justify-center
        mt-12
        lg:mt-28">
            <h2 className="text-center
            text-2xl
            lg:text-5xl
             ">
                We Asked. You Cheer Faced.
            </h2>

            <p className="text-center
            text-xs mt-4
            lg:text-base
            ">
                Go Edmonton. Your enthusiasm is legendary. See yours and all the other game-ready Cheer Faces in our
                gallery. And share your submission on other platforms using the hashtag #FanUp2026.
            </p>
        </div>

        {/*Photos*/}
        <div className="container mx-auto flex flex-col justify-center
            w-full mt-7
            lg:w-8/12 lg:mt-14">
            <Photos setShowLoadMore={setShowLoadMore} showLoadMore={showLoadMore} photoPage={photoPage}></Photos>

            <button className={"btn-submit mt-8 " +
            "btn-submit-sm " +
            "lg:btn-submit-lg " +
            (!showLoadMore && 'hidden ')}
                    onClick={() => {
                        setPhotoPage(photoPage + 1)
                    }}>
                Load More
            </button>
        </div>

        {/*Soccer Ball*/}
        <div className="bg-left-top right-0 -mt-64
        soccer-ball soccer-ball-w-180
        lg:soccer-ball lg:soccer-ball-w-360
        "></div>

        {/*Show Your Support!*/}
        <div className="w-full bg-red-full
        mt-10
        lg:mt-20">
            <div className=" container mx-auto flex flex-col justify-center
            mt-14 pb-7 w-full
            lg:mt-30 lg:pb-14 lg:w-8/12">

                <h2 className="text-center text-white
                text-lg mt-10
                whitespace-normal
                lg:text-5xl lg:mt-20
                xl:whitespace-nowrap
                ">
                    Show your support!<br/>
                    Submit your Cheer Face!
                </h2>

                <a href="/submit"
                   className="btn-submit submit border border-white text-white hover:text-red-full
                   btn-submit-sm mt-4
                   lg:btn-submit-lg lg:mt-8
                   ">
                    Submit Now
                </a>
            </div>
        </div>

        <div className="w-full">
            <div className=" container mx-auto flex flex-col justify-center
            mt-8 pb-7 w-full
            lg:pb-14 lg:w-8/12">

                <h2 className="text-center
                mb-8
                text-lg mt-10
                lg:text-5xl lg:mt-20
                ">
                    Secure your seats for Canada’s crucial FIFA World Cup Qatar 2022™ qualifiers in Edmonton
                </h2>

                <p className="text-center">
                    What is better than being a Cheer Face Winner? Being a Cheer Face Winner AND securing your seat in
                    the sea of red at Commonwealth Stadium as Canada faces Costa Rica and Mexico this November!
                </p>

                <p className="text-center mt-8">
                    <h3 className="mb-4">Canada v Costa Rica – November 12</h3>

                    <a href="https://www.ticketmaster.ca/canada-mnt-vs-costa-rica-fifa-edmonton-alberta-11-12-2021/event/11005B521B1B3A0E?CAMEFROM=CFC_CDNSOCCER_WEB_EE_CHEERFACE"
                       target="_blank" rel="noreferrer"
                       className="btn-submit submit btn-black
                       btn-submit-sm mt-4
                       lg:btn-submit-lg lg:mt-8
                   ">
                        Buy Now
                    </a>
                </p>

                <p className="text-center mt-8">
                    <h3 className=" mb-4">Canada v Mexico – November 16</h3>

                    <a href="https://www.ticketmaster.ca/canada-mnt-vs-mexico-fifa-world-edmonton-alberta-11-16-2021/event/11005B521C243A2C?CAMEFROM=CFC_CDNSOCCER_WEB_EE_CHEERFACEMEX"
                       target="_blank" rel="noreferrer"
                       className="btn-submit submit  btn-black
                       btn-submit-sm mt-4
                       lg:btn-submit-lg lg:mt-8
                   ">
                        Buy Now
                    </a>
                </p>

            </div>
        </div>

        <SiteFooter/>
    </div>
}

export default Home;
