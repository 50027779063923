import {SiteFooter, SiteHeader} from "./Templates";

function ContestRules() {
    return <div>
        <SiteHeader/>
        <div className="mx-auto
        w-10/12
        lg:w-1/2 pb-10">
            <div className="relative container mx-auto flex flex-col text-center items-center">
                <h1 className="w-full pb-11
            text-4xl
            md:text-5xl
            lg:text-7xl">Cheer Face Complete Contest Rules</h1>
            </div>

            <p className="my-2"><strong>Complete Contest Rules</strong></p>
            <p className="my-2">No purchase necessary.</p>

            <ol className="list-decimal">
                <li><strong>Contest Period and Entry. </strong>The<strong> Edmonton World Cup 2026 Bid - Cheer Face
                    Contest </strong>(the “Contest”) begins at 8:00 a.m. Mountain Standard Time (MST) on Monday,
                    November 1, 2021. Contest entries will be accepted until 11:59 p.m. MST on November 21, 2021.
                </li>
                <li><strong>Eligibility.</strong> The Contest is open to residents of Edmonton, AB who have reached
                    the legal age of majority in their province/territory of residence at the time of entry, except
                    employees, representatives or agents (and those with whom such persons are domiciled, whether
                    related or
                    not) of the City of Edmonton, and Explore Edmonton Corporation (the “<strong>Sponsor</strong>”), or
                    DDB
                    Canada, a division of Omnicom Canada Corp., and each of their respective parent companies,
                    subsidiaries,
                    affiliates, prize suppliers, advertising/promotion agencies and any entity involved in the
                    development,
                    production, implementation, administration, judging or fulfillment of the Contest (collectively, the
                    “<strong>Contest Parties</strong>”).
                </li>
                <li><strong>How to Enter.</strong> NO PURCHASE NECESSARY. To enter, you must fill out the entry
                    form at [INSERT URL], You must include your first name, last name, email address, and an image of
                    your
                    “cheer face” (your “Submission”). Collected information will only be used to contact the winner. By
                    providing an email address, it will be deemed to be made by the authorized email account holder at
                    time
                    of entry. "Authorized account holder" is deemed as the natural person who is assigned to an email
                    address. Anyone found to use multiple email accounts to enter will be ineligible. There is a
                    maximum of one entry per person during the contest period. No responsibility is assumed by Explore
                    Edmonton Corporation for any computer, online telephone or technical malfunctions that may occur in
                    the
                    processing, transmission or receipt of entries. Contestants may also opt into an Explore Edmonton
                    Corporation consumer newsletter.
                </li>
                <li>Your Submission may be rejected if (in the sole and absolute discretion of the Sponsor): (i) your
                    Submission does not depict the cheer face theme; and/or (ii) your Submission (or a component
                    thereof) is
                    not in accordance with the specific Submission Requirements listed below in Rule 4 (all as
                    determined by
                    Sponsor in its sole and absolute discretion). The Released Parties as defined below, are not
                    responsible for late, lost, misdirected, delayed, incomplete or incompatible Submissions (all of
                    which
                    are void).
                </li>
                <li>All Submissions are subject to verification at any time and for any reason. The Sponsor reserves
                    the right, in its sole and absolute discretion, to require proof of identity and/or eligibility (in
                    a
                    form acceptable to the Sponsor – including, without limitation, government issued photo
                    identification):
                    (i) for the purposes of verifying the eligibility and/or legitimacy of any Submission entered (or
                    purportedly entered) for the purposes of this Contest; and/or (iii) for any other reason the Sponsor
                    deems necessary, in its sole and absolute discretion, for the purposes of administering this Contest
                    in
                    accordance with these Rules. Failure to provide such proof to the complete satisfaction of the
                    Sponsor in a timely manner may result in disqualification in the sole and absolute discretion of the
                    Sponsor. The sole determinant of the time for the purposes of a valid Submission in this Contest
                    will be the Contest server machine(s).
                </li>
                <li><strong>Submission Requirements:</strong> <br/> <br/>BY SUBMITTING A SUBMISSION, YOU AGREE THAT THE
                    SUBMISSION COMPLIES WITH ALL CONDITIONS STATED IN THESE RULES. THE RELEASED PARTIES WILL BEAR NO
                    LIABILITY OF ANY KIND REGARDING THE USE OF YOUR SUBMISSION OR ANY COMPONENT THEREOF. THE RELEASED
                    PARTIES SHALL BE HELD HARMLESS BY YOU IN THE EVENT IT IS SUBSEQUENTLY DISCOVERED THAT YOU HAVE
                    DEPARTED
                    FROM OR NOT FULLY COMPLIED WITH ANY OF THESE RULES. <br/> <br/>
                    <ol className="list-alpha ml-6">
                        <li>To be eligible for entry in this Contest, your Submission must depict the cheer face theme
                            and never have been previously selected as a winner in any other contest.
                        </li>
                        <li>By participating in the Contest, each Entrant hereby warrants and represents that his/her
                            Submission (and each component thereof):
                            <ol className="list-decimal ml-8">
                                <li>is original to him/her and that the Semi-Finalist has (or has obtained) all
                                    necessary rights (including, without limitation, consent of the videographer or
                                    photographer, as applicable) in and to the Submission (and all components thereof)
                                    for the purposes of entering the Submission in the Contest;
                                </li>
                                <li>does not violate any law, statute, ordinance or regulation;</li>
                                <li>does not contain any reference to or likeness of any identifiable third parties,
                                    unless consent has been obtained from each such individual and his/her parent/legal
                                    guardian if he/she is under the legal age of majority in his/her jurisdiction of
                                    residence (note: if you cannot obtain the consent of an individual appearing in
                                    your Submission, then his/her face must be blurred out and voice, if
                                    applicable, must be altered so as to be unrecognizable);
                                </li>
                                <li>will not give rise to any claims of infringement, invasion of privacy or publicity,
                                    or infringe on any rights and/or interests of any third party, or give rise to any
                                    claims whatsoever; and
                                </li>
                                <li> is not defamatory, trade libelous, pornographic or obscene, and further that
                                    it will not contain, depict, include, discuss or involve, without limitation, any of
                                    the following: nudity; alcohol/drug consumption or smoking; explicit or graphic
                                    sexual activity, or sexual innuendo; crude, vulgar or offensive language and/or
                                    symbols; derogatory characterizations of any ethnic, racial, sexual, religious or
                                    other groups (including, without limitation, any competitors of Sponsor); content
                                    that endorses, condones and/or discusses any illegal, inappropriate or risky
                                    behaviour or conduct; personal information of individuals, including, without
                                    limitation, names, telephone numbers and addresses (physical or electronic);
                                    commercial messages, comparisons or solicitations for products or services other
                                    than products of Sponsor; any identifiable third party products, trade-marks, brands
                                    and/or logos, other than those of Sponsor (e.g. any clothing worn and/or products
                                    appearing in your Video or Photo must not contain any visible logos, trade-marks or
                                    other third party materials unless the appropriate consents have been obtained ---
                                    note: all identifiable third party products, trade-marks, brands and/or logos for
                                    which consent has not been obtained by the entrant must be blurred out so as to be
                                    unrecognizable); conduct or other activities in violation of these Rules; and/or any
                                    other content that is or could be considered inappropriate, unsuitable or offensive,
                                    all as determined by the Sponsor in its sole and absolute discretion.
                                </li>
                            </ol>
                        </li>
                        <li>The Sponsor and/or its promotional agency or designated content moderator (the
                            “<strong>Reviewer</strong>”) will initially screen every
                            Submission. Any Submission (or any component of a Submission) that the
                            Reviewer deems, in its sole and absolute discretion, to violate the terms and conditions set
                            forth in these Rules is subject to disqualification. The Reviewer reserves the right,
                            in its sole and absolute discretion at any time, to modify, edit or remove
                            any Submission (or any component of a Submission), or to request an entrant to modify,
                            edit and/or re-submit his or her Submission (or any component of a Submission), in
                            order to ensure that the Submission complies with these Rules, or for any other
                            reason.
                        </li>
                    </ol>
                </li>
                <li><strong>License, Waiver and Release: </strong>By entering the Contest and submitting a Submission,
                    each Entrant: (i) grants to the Sponsor, in perpetuity, a non-exclusive license to publish, display,
                    reproduce, modify, edit or otherwise use his/her Submission, in whole or in part, for advertising
                    or promoting the Contest, or City of Edmonton or its bid to be a host city for the World Cup or for
                    any
                    other reason; (ii) waives all moral rights in and to his/her Submission in favour of the Sponsor;
                    and (iii) agrees to release and hold harmless Released Parties from and against any and all claims,
                    damages, liabilities, costs, and expenses arising from use of his/her Submission, including, without
                    limitation, any claims, damages, liabilities, costs, and expenses relating in any way to publicity
                    rights, defamation, invasion of privacy, copyright infringement, trade-mark infringement or any
                    other
                    intellectual property related cause of action.
                </li>
                <li><strong>Winner Selection. </strong>On each of Monday, November 8, 2021 at 11 a.m.; Monday, November
                    15, 2021 at 11 a.m.; Monday, November 22, 2021 at 11 a.m. (the “Draw Dates”) in Edmonton at
                    approximately 2:00 p.m. ET, • (•) eligible entrants will be selected to become eligible Winners by
                    random draw from among all eligible Entries submitted and received during the Entry Period in
                    accordance
                    with these Rules. Entries prior to each draw date will be rollover into the draws that follow until
                    the
                    contest ends. Users do not need to submit another Cheer Face for each contest to be included in each
                    draw after their initial submission. <br/> <br/>The odds of being selected to become an eligible
                    Winner depend on the number of eligible Entries submitted and received during the Entry Period in
                    accordance with these Rules. The Sponsor or its designated representative will make a minimum
                    of three (3) attempts to contact each eligible Winner (using the information provided on the Entry
                    Form) within three (3) business days of the Draw Date. If an eligible Winner cannot be
                    contacted within three (3) business days of the Draw Date, or if there is a return of any
                    notification as undeliverable; then he/she may, in the sole and absolute discretion of the Sponsor,
                    be disqualified (and, if disqualified, will forfeit all rights to be a Winner) and the Sponsor
                    reserves the right, in its sole and absolute discretion and time permitting, to randomly select an
                    alternate eligible entrant from among the remaining eligible Entries to become an eligible Winner
                    (in which case the foregoing provisions of this section shall apply to such newly selected eligible
                    Winner). BEFORE BEING DECLARED A CONFIRMED WINNER (each, a “Winner”), each eligible Winner
                    will be required to: (a) correctly answer a mathematical skill-testing question without mechanical
                    or other aid; and (b) sign and return within five (5) business days of notification the Sponsor's
                    declaration and release form, which (among other things): (i) confirms compliance with these Rules;
                    (ii) releases the Contest Parties and each of their respective officers, directors, agents,
                    representatives, successors and assigns (collectively, the “Released Parties”) from any and all
                    liability in connection with this Contest and his/her participation therein and; and (iii) agrees to
                    the publication, reproduction and/or other use of his/her name, address, voice, statements about the
                    Contest and/or photograph or other likeness without further notice or compensation, in any publicity
                    or advertisement carried out by or on behalf of the Sponsor in any manner whatsoever, including
                    print, broadcast or the internet. If an eligible Winner: (a) fails to correctly answer the
                    skill-testing question; (b) fails to return the properly executed Contest documents within the
                    specified time; (c) cannot be (or is unwilling to be) a Winner for any reason whatsoever;
                    and/or (d) is determined to be in violation of these Rules (all as determined by the Sponsor in its
                    sole and absolute discretion); then he/she may, in the sole and absolute discretion of the Sponsor,
                    be disqualified (and, if disqualified, will forfeit all rights to be a Winner) and the Sponsor
                    reserves the right, in its sole and absolute discretion and time permitting, to randomly select an
                    alternate eligible entrant from among the remaining eligible Entries to become an eligible Winner
                    (in which case the foregoing provisions of this section shall apply to such newly selected eligible
                    Winner).
                </li>
                <li><strong>Prizes. </strong>There will be • (•)Prizes available to be won, each consisting of:
                    [describe each prize in detail and include approximate retail value of each prize – you can break
                    down
                    grand prize and secondary prizes.] The prize must be accepted as awarded and may not be
                    transferred or exchanged absent the consent of the Contest sponsors, which consent may be withheld
                    in
                    their absolute discretion. The Contest prize may not be exactly as advertised. The Contest sponsors
                    reserve the right, in their absolute discretion, to substitute a prize of equal or greater value in
                    the
                    event of the unavailability, for whatever the reason, of the advertised prize in whole or in part.
                </li>
                <li><strong>General Conditions</strong>. All Entry materials become the property of the Sponsor. This
                    Contest is subject to all applicable federal, provincial and municipal laws. The decisions of the
                    Sponsor with respect to all aspects of this Contest are final and binding on all entrants without
                    right
                    of appeal, including, without limitation, any decisions regarding the eligibility/disqualification
                    of
                    Entries, Submissions and/or entrants. By participating in this Contest, you are agreeing to be
                    legally bound by the terms and conditions of these Rules. ANYONE DETERMINED TO BE IN VIOLATION OF
                    THESE
                    RULES FOR ANY REASON IS SUBJECT TO DISQUALIFICATION IN THE SOLE AND ABSOLUTE DISCRETION OF THE
                    SPONSOR
                    AT ANY TIME. The Released Parties will not be liable for: (i) any failure of the Website during
                    the Contest; (ii) any technical malfunction or other problems relating to the telephone network or
                    lines, computer on-line systems, servers, access providers, computer equipment or software; (iii)
                    the
                    failure of any Entry, Submission or other material to be received, captured or recorded for any
                    reason,
                    including, but not limited to, technical problems or traffic congestion on the internet or at any
                    website; (iv) any injury or damage to an entrant's or any other person's computer or other device
                    related to or resulting from participating in the Contest; and/or (v) any combination of the above.
                    The
                    Sponsor reserves the right to withdraw, amend or suspend this Contest (or to amend these Rules) in
                    any
                    way, in the event of an error, technical problem, computer virus, bugs, tampering, unauthorized
                    intervention, fraud, technical failure or any other cause beyond the reasonable control of the
                    Sponsor
                    that interferes with the proper conduct of this Contest as contemplated by these Rules. Any
                    attempt to damage any website or to undermine the legitimate operation of this Contest in any way
                    (as
                    determined by Sponsor in its sole and absolute discretion) is a violation of criminal and civil laws
                    and
                    should such an attempt be made, the Sponsor reserves the right to seek remedies and damages to the
                    fullest extent permitted by law. The Sponsor, reserves the right to cancel, amend or suspend this
                    Contest, or to amend these Rules, in any way without prior notice or obligation, in the event of any
                    accident, printing, administrative, or other error of any kind, or for any other reason
                    whatsoever. Without limiting the generality of the forgoing, the Sponsor reserves the right, in
                    its sole and absolute discretion, to administer an alternate test of skill as it deems appropriate
                    based
                    on the circumstances and/or to comply with applicable law.
                </li>
                <li><strong>Privacy Compliance. </strong>By entering this Contest each entrant expressly consents
                    to the Sponsor, its agents and/or representatives, storing, sharing and using the personal
                    information
                    submitted with his/her Entry only for the purpose of administering the Contest and in accordance
                    with
                    Sponsor's privacy policy <a
                        href="https://exploreedmonton.com/privacy-policy">https://exploreedmonton.com/privacy-policy</a> and
                    the Sponsor's cookie policy <a
                        href="https://protect-eu.mimecast.com/s/OyULC59nzs30Ap3CyhMqc">https://exploreedmonton.com/cookie-policy</a>.
                    This
                    information is being collected by Explore Edmonton Corporation under the authority of section 33(c)
                    of
                    the Alberta Freedom of Information and Protection of Privacy Act, and will be used for Edmonton
                    World
                    Cup 2026 Bid - Cheer Face Contest purposes. If you have any questions about the collection, use or
                    disclosure of information for this Contest you may contact Explore Edmonton Corporation at <a
                        href="mailto:info@exploreedmonton.com">info@exploreedmonton.com</a>. Each entrant further
                    consents and agrees to receiving electronic messages sent from or on behalf of the Sponsor in
                    relation
                    to the Contest, its administration, use of Submissions by the Sponsor in accordance with these
                    Contes
                    Rules, Prizes or awarding of Prizes. During your submission, we may ask you if you would like to
                    receive marketing communications. If you opt-in, then you will receive communications according to
                    what
                    you opted into.
                </li>
            </ol>
        </div>
        <SiteFooter/>
    </div>;
}

export default ContestRules;
